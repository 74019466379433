import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'src/redux/store';
import Api from 'src/services/ApiService';
import { CommonAxiosResponse } from 'src/types';
import {
  CreatePCBundleResponse,
  GetBundleDetailsResponse,
  GetBundleListResponse,
  UpdateBundleRequest,
  UpdateBundleResponse
} from './types';
import { urlConvertToBase64 } from 'src/utils';

export const createPCBundleThunk = createAsyncThunk<
  CommonAxiosResponse<CreatePCBundleResponse>,
  undefined,
  { state: RootState }
>('pc-bundles/createPCBundleThunk', async (_, api) => {
  const request = api?.getState()?.pcBundles?.bundleDetails;
  const response = await Api.post('/ecomm/internal/pc_bundle/create', request);
  return response;
});

export const getPCBundleListThunk = createAsyncThunk<
  CommonAxiosResponse<GetBundleListResponse>,
  undefined,
  { state: RootState }
>('pc-bundles/getPCBundleListThunk', async (_, api) => {
  const request = api?.getState()?.pcBundles?.filters;
  const response = await Api.get('ecomm/internal/pc_bundle/list', request);
  if (!response.success) {
    console.error('Failed to get bundles list');
  }
  return response;
});

export const getPCBundleDetailsThunk = createAsyncThunk<
  CommonAxiosResponse<GetBundleDetailsResponse>,
  number
>('pc-bundles/getPCBundleDetailsThunk', async (id: number) => {
  const response = await Api.get(`ecomm/internal/pc_bundle/details/${id}`);
  if (!response.success) {
    console.error('Failed to getPCBundleDetailsThunk');
  }
  return response;
});

export const updateBundleThunk = createAsyncThunk<
  CommonAxiosResponse<UpdateBundleResponse>,
  UpdateBundleRequest,
  { state: RootState }
>('pc-bundles/updateBundleThunk', async (data, api) => {
  const state: any = api?.getState()?.pcBundles.bundleDetails;
  const request = Object.keys(state).reduce((diff: any, key) => {
    const stateVal = state[key];
    const ogVal = data.ogVal[key];

    // Compare arrays/obj by their content using JSON.stringify
    if (Array.isArray(stateVal) && Array.isArray(ogVal)) {
      if (JSON.stringify(stateVal) !== JSON.stringify(ogVal)) {
        diff[key] = stateVal;
      }
    }
    // For non-arrays, use standard comparison
    else if (stateVal !== ogVal) {
      diff[key] = stateVal;
    }

    return diff;
  }, {});

  // if request has images in url string format convert to base64
  if (request?.images?.[0]) {
    const filteredImages = request.images.map((i: any) => {
      const file = i?.file;
      if (/https:\/\//.test(file)) {
        return { file: urlConvertToBase64(file) };
      }
      return i;
    });

    request.images = filteredImages;
  }

  const response = await Api.patch(
    `ecomm/internal/pc_bundle/update/${data.id}`,
    request
  );
  if (!response.success) {
    console.error('Failed at updateBundleThunk');
  }
  return response;
});

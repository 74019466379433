import {
  Card,
  CardContent,
  Typography,
  TextField,
  TextareaAutosize,
  Grid,
  Box,
  Select,
  MenuItem
} from '@material-ui/core';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { UploadThumbnail } from './UploadThumbnail';
import { convertImageFileToBase64 } from 'src/utils';
import { BundleDetailsV2 } from 'src/redux/slices/pc-bundles/types';
import { slices, useAppDispatch, useAppSelector } from 'src/redux';
import { BundleStatusEnum } from 'src/enums';
import { isEmpty } from 'lodash';

interface Props {
  bundle?: BundleDetailsV2;
}

const {
  selectors: pcBundleSelectors,
  actions: pcBundleActions
} = slices.pcBundles;

export const BundleGeneralInfo: FC<Props> = ({ bundle }) => {
  const dispatch = useAppDispatch();
  const bundleStatus = Object.entries(BundleStatusEnum);

  const title = useAppSelector(pcBundleSelectors.selectTitle);
  const thumbnail = useAppSelector(pcBundleSelectors.selectThumbnail);
  const description = useAppSelector(pcBundleSelectors.selectDescription);
  const status = useAppSelector(pcBundleSelectors.selectStatus);

  const [thumbnailState, setThumbnailState] = useState<string>('');
  const [statusState, setStatusState] = useState<string>('');
  // may re-use in the future
  // const [useCoreOnDesc, setUseCoreOnDesc] = useState<boolean>(
  //   bundle?.useCore || false
  // );

  // if has bundle and has id it is assumed user is on details page
  const isDetailsPage = useMemo(() => bundle?.title, [bundle]);

  // may re-use in the future
  // const onChangeUseCore = useCallback(() => {
  //   dispatch(pcBundleActions.setUseCore(!useCoreOnDesc));
  //   setUseCoreOnDesc(!useCoreOnDesc);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [useCoreOnDesc]);

  const onChangeDesc = useCallback((e: any) => {
    dispatch(pcBundleActions.setDesc(String(e.target.value)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChangeStatus = useCallback((e: any) => {
    dispatch(pcBundleActions.setStatus(String(e.target.value)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChangeTitle = useCallback((e: any) => {
    dispatch(pcBundleActions.setTitle(String(e.target.value)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onImageSelected = async (img: File[]) => {
    if (img && img?.length > 0) {
      const firstFile = img[0];
      const imgInBase64: any = await convertImageFileToBase64(firstFile);
      dispatch(pcBundleActions.setThumbnail(imgInBase64));
    }
  };

  const uploadBreakpoint = useMemo(() => (thumbnailState ? 9 : 12), [
    thumbnailState
  ]);

  useEffect(() => {
    if (!isEmpty(bundle)) {
      dispatch(pcBundleActions.setTitle(bundle?.title || ''));
      dispatch(pcBundleActions.setThumbnail(bundle?.thumbnail || ''));
      dispatch(pcBundleActions.setDesc(bundle?.description || ''));
      dispatch(
        pcBundleActions.setStatus(
          bundle?.status || BundleStatusEnum.Unpublished
        )
      );
    }
  }, [bundle, dispatch]);

  useEffect(() => {
    status && setStatusState(status);
  }, [status]);

  useEffect(() => {
    setThumbnailState(thumbnail || '');
  }, [thumbnail]);

  return (
    <Card>
      <CardContent>
        <Grid container style={{ rowGap: '1em' }}>
          <Grid
            item
            container
            style={{ justifyContent: 'space-between' }}
            xs={12}
          >
            {isDetailsPage ? (
              <>
                {thumbnailState && (
                  <Grid
                    item
                    container
                    xs={1}
                    style={{
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}
                  >
                    <img src={thumbnailState} height={70} />
                  </Grid>
                )}
                <Grid item xs={11} style={{ paddingRight: '1em' }}>
                  <Typography>Title</Typography>
                  <TextField
                    fullWidth
                    value={title}
                    variant="outlined"
                    onChange={onChangeTitle}
                    placeholder="Enter bundle title"
                  />
                </Grid>
                <Grid item xs={12} container style={{ marginTop: '1em' }}>
                  <Grid
                    item
                    container
                    xs={6}
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      paddingRight: '1em'
                    }}
                  >
                    <Typography>Update Thumbnail</Typography>
                    <UploadThumbnail onImageSelected={onImageSelected} />
                  </Grid>
                  <Grid
                    item
                    container
                    xs={6}
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      paddingRight: '1em'
                    }}
                  >
                    <Typography>Status</Typography>
                    <Select
                      value={statusState}
                      variant="outlined"
                      onChange={onChangeStatus}
                    >
                      {bundleStatus.map((i) => (
                        <MenuItem key={i[0]} value={i[1]}>
                          {i[0]}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                </Grid>
              </>
            ) : (
              <>
                <Grid item xs={6} style={{ paddingRight: '1em' }}>
                  <Typography>Title</Typography>
                  <TextField
                    fullWidth
                    defaultValue={bundle?.title}
                    value={title}
                    variant="outlined"
                    onChange={onChangeTitle}
                    placeholder="Enter bundle title"
                  />
                </Grid>
                <Grid item container xs={6}>
                  <Grid item xs={uploadBreakpoint}>
                    <Typography>Upload Thumbnail</Typography>
                    <UploadThumbnail onImageSelected={onImageSelected} />
                  </Grid>
                  {thumbnailState && (
                    <Grid
                      item
                      container
                      xs={3}
                      style={{
                        alignItems: 'center',
                        justifyContent: 'center'
                      }}
                    >
                      <img src={thumbnailState} height={70} />
                    </Grid>
                  )}
                </Grid>
              </>
            )}
          </Grid>
          <Grid item xs={12}>
            <Box style={{ display: 'flex', alignItems: 'center' }}>
              <Typography>Description</Typography>
              {/* remove for now */}
              {/* <Checkbox
                checked={useCoreOnDesc}
                onClick={onChangeUseCore}
                color="primary"
              />
              <Typography>Use the core components</Typography> */}
            </Box>
            <TextareaAutosize
              value={description}
              onChange={onChangeDesc}
              placeholder="PC Bundle Description"
              style={{
                fontFamily: 'roboto',
                fontSize: '.9em',
                width: '100%',
                height: '5em',
                border: '1px solid #ccc',
                borderRadius: 4,
                padding: '10px 12px'
              }}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

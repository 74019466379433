import React, { useEffect, useMemo, useState, useRef } from 'react';
import { cloneDeep, debounce, isEmpty } from 'lodash';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { DatePicker } from '@material-ui/pickers';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Divider,
  Grid,
  IconButton,
  TextField,
  makeStyles,
  Typography,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  colors
} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import SaveIcon from '@material-ui/icons/Save';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { CheckBoxLabel, LoadingButton, Page } from 'src/components';
import { listingPriceTypeOptions, localize, warranties } from 'src/constants';
import { useLocation } from 'react-router-dom';
import {
  cleanSN,
  copyToClipboard,
  dateToday,
  formatCurrency,
  formatDate,
  getRandomUUIDv4Short,
  getWarrantyInDays,
  isNormalInteger,
  toUtcEquivalentDate
} from 'src/utils';
import {
  CreateListingProduct,
  CustomInputEvent,
  CustomKeyboardEvent,
  FixMeLater
} from 'src/types';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Supplier } from 'src/types/supplier';
import { slices, useAppDispatch, useAppSelector } from 'src/redux';
import {
  useDebouncedEffect,
  useNotifSound,
  useSnackBar,
  useAlertGlobal
} from 'src/hooks';
import InputAdornment from '@material-ui/core/InputAdornment';
import { unwrapResult } from '@reduxjs/toolkit';
import { ListingPriceTypeEnum, ProductTypeEnum } from 'src/enums';
import { getGrossColor } from 'src/utils';
import FormAddDialog from 'src/components/dialogs/FormDialog';
import { TemporaryListings } from '../component';
import usePrompt from 'src/utils/navigation-prompt';
import { useBranchInfo } from 'src/hooks/branch/use-branch-info';
import { multiBranchFeat } from 'src/constants/feature-toggle';
import useUserInfo from 'src/hooks/user/use-user-info';
import moment from 'moment';
import { ListingAddListError } from '../component/ListingAddListError';

const {
  actions: supplierActions,
  selectors: supplierSelectors
} = slices.supplier;
const { actions: listingActions, selectors: listingSelectors } = slices.listing;

const useStyles = makeStyles((theme) => ({
  root: {},
  subHeader: {
    marginBottom: theme.spacing(2)
  },
  formControl: {
    marginBottom: theme.spacing(2)
  },
  suggestedPriceText: {
    marginBottom: theme.spacing(2),
    textDecoration: 'underline',
    cursor: 'pointer',
    color: colors.orange[400]
  },
  drText: {
    display: 'flex',
    flexDirection: 'row',
    textDecoration: 'underline',
    cursor: 'pointer',
    color: colors.orange[400]
  }
}));

const AddProductListingView = () => {
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const location = useLocation();
  const snackBar = useSnackBar();
  const notifSound = useNotifSound();
  const alertGlobal = useAlertGlobal();
  const {
    branches,
    getBranches,
    branchInputValue,
    setBranchInputValue,
    selectedBranch,
    setSelectedBranch
  } = useBranchInfo();
  const { userBranchOptions } = useUserInfo();

  const newBranchesOptions = useMemo(() => {
    return branches.filter((branch) =>
      userBranchOptions.some(
        (userBranch) => userBranch.name === branch.branch_name
      )
    );
  }, [branches, userBranchOptions]);

  const state: any = location.state;
  const temporaryListingLimit = 100;
  const suppliers = useAppSelector(supplierSelectors.selectSupplierList);
  const listingDRNoData = useAppSelector(
    listingSelectors.selectListingsRecentDRNos
  );
  const listingRecentPurchaseDates = useAppSelector(
    listingSelectors.selectPurchaseListingDate
  );
  const listingRecentSuppliers = useAppSelector(
    listingSelectors.selectRecentSuppliers
  );
  const listingRecentBranches = useAppSelector(
    listingSelectors.selectRecentBranches
  );
  const serialNoFieldRef = useRef<any>(null);
  const quantityFieldRef = useRef<any>(null);
  const [recentListings, setRecentListings] = useState<CreateListingProduct[]>(
    []
  );
  const [errRecentListings, setErrRecentListings] = useState<
    CreateListingProduct[]
  >([]);
  const [priceType, setPriceType] = useState<1 | 2 | 3>(
    ListingPriceTypeEnum.NotFree
  );
  const [isCreateSupplierVisible, setIsCreateSupplierVisible] = useState<
    boolean
  >(false);
  const [addSupplierLoading, setAddSupplierLoading] = useState<boolean>(false);
  const [hasNoSerial, setHasNoSerial] = useState(false);
  const [isSNValidLoading, setIsSNValidLoading] = useState<boolean>(false);
  const [autoAddOnEnter, setAutoAddOnEnter] = useState(true);
  const [supplierInput, setSupplierInput] = useState('');
  const [warrantyInput, setWarrantyInput] = useState('');
  const [purchaseDate, setPurchaseDate] = useState<Date | null>(null);
  const [dateChanged, setDateChanged] = useState(false);
  const [drNo, setDrNo] = useState<string>('');
  const [updateProductSrp, setUpdateProductSrp] = useState<boolean>(true);
  const [updateProductDp, setUpdateProductDp] = useState<boolean>(true);
  const [selectedSupplier, setSelectedSupplier] = useState<Supplier | null>(
    null
  );
  const [selectedWarranty, setSelectedWarranty] = useState<any | null>(null);
  const [values, setValues] = useState({
    serial_no: '',
    retail_price: '',
    dealers_price: '',
    dr_no: '',
    quantity: 1
  });
  const [errFields, setErrFields] = useState({
    dr_no: '',
    purchaseDate: '',
    supplier: '',
    warranty: '',
    dealers_price: '',
    retail_price: '',
    serial_no: ''
  });
  const [currrentDrNo, setCurrentDrNo] = useState<string>(); //for comparison of prev dr no

  const fieldValidations = [
    {
      field: 'dr_no',
      value: drNo,
      errorMessage: 'DR NO IS REQUIRED'
    },
    {
      field: 'purchaseDate',
      value: purchaseDate,
      errorMessage: 'PURCHASE DATE ON SUPPLIER IS REQUIRED'
    },
    {
      field: 'supplier',
      value: selectedSupplier,
      errorMessage: 'SUPPLIER IS REQUIRED'
    },
    {
      field: 'warranty',
      value: selectedWarranty,
      errorMessage: 'WARRANTY IS REQUIRED'
    },
    {
      field: 'serial_no',
      value: values.serial_no,
      errorMessage: 'SERIAL NUMBER IS REQUIRED'
    },
    {
      field: 'retail_price',
      value: values.retail_price,
      errorMessage: 'RETAIL PRICE IS REQUIRED'
    }
  ];

  const areFieldsValid = () => {
    let areValid = true;
    fieldValidations.forEach((validation) => {
      const { field, value, errorMessage } = validation;
      if (!value) {
        if (field === 'serial_no' && hasNoSerial) {
          return;
        }
        //exclude serial no in checking error if product is consumable
        if (isProductConsumable && field === 'serial_no') {
          return;
        }
        setErrFields((prev) => ({ ...prev, [field]: errorMessage }));
        areValid = false;
      } else {
        setErrFields((prev) => ({ ...prev, [field]: '' }));
      }
    });

    return areValid;
  };

  const validateField = (name: string, value: any) => {
    const targetValidation = fieldValidations.find(
      (validation) => validation.field === name
    );

    if (targetValidation) {
      const { field, errorMessage } = targetValidation;

      if (!value) {
        setErrFields((prev) => ({ ...prev, [field]: errorMessage }));
      } else {
        setErrFields((prev) => ({ ...prev, [field]: '' }));
      }
    }
  };

  const handleSetDR = () => {
    dispatch(listingActions.addRecentDRNos(drNo));
    // if there is new dr call api
    if (!listingDRNoData?.includes(drNo)) {
      dispatch(listingActions.updateRecentDrNoThunk());
    }
  };

  const handleSetRecentDates = () => {
    const convertedDate = moment(purchaseDate).toISOString(); //use for comparison
    //set recent dates array in redux
    if (purchaseDate !== null) {
      dispatch(
        listingActions.addRecentPurchaseDates(purchaseDate.toISOString())
      );

      //if there is new date call api
      if (!listingRecentPurchaseDates?.includes(convertedDate)) {
        dispatch(listingActions.updateRecentPurchasedDateThunk());
      }
    }
  };

  const handleSetSuppliers = () => {
    const currentSupplier = JSON.stringify(selectedSupplier);
    if (selectedSupplier !== null) {
      dispatch(listingActions.addRecentSuppliers(selectedSupplier));
    }

    //if there is new supplier call this api
    if (!listingRecentSuppliers.includes(currentSupplier)) {
      dispatch(listingActions.updateRecentSupplierThunk());
    }
  };

  const handleSetBranches = () => {
    if (selectedBranch !== null) {
      dispatch(listingActions.addRecentBranches(selectedBranch));
    }
  };

  const isProductConsumable = useMemo(
    () => state?.product_type === ProductTypeEnum.Consumable,
    [state]
  );

  const onOpenCreateSupplierDialog = () => {
    setIsCreateSupplierVisible(true);
  };

  const onCloseCreateSupplierDialog = () => {
    setIsCreateSupplierVisible(false);
    setAddSupplierLoading(false);
  };

  const onAddSupplierOnModalPress = async (name: string) => {
    // TODO: Add checker loader before close.
    setAddSupplierLoading(true);
    if (name) {
      const response = unwrapResult(
        await dispatch(supplierActions.createSupplierThunk({ name }))
      );
      if (response.success) {
        snackBar.show({ severity: 'success', message: response.message });
        onCloseCreateSupplierDialog();
      } else {
        snackBar.show({ severity: 'error', message: response.message });
        setAddSupplierLoading(false);
      }
    } else {
      snackBar.show({
        severity: 'error',
        message: 'Please fill up all required information.'
      });
    }
  };

  const quantityHelperText = useMemo(() => {
    if (isProductConsumable && state?.consumable_unit) {
      return `Enter quantity in ${state?.consumable_unit}(s). Quantity is needed for consumable items.`;
    }
    if (isProductConsumable) {
      return `Enter quantity. Quantity is needed for consumable items.`;
    }
    return 'Enter quantity. Item(s) with no serial number will have a unique UUID instead.';
  }, [isProductConsumable, state]);

  const isDealersPriceFieldDisabled = useMemo(
    () =>
      priceType === ListingPriceTypeEnum.FreeFromSupplier ||
      priceType === ListingPriceTypeEnum.FreeForAll,
    [priceType]
  );

  const isRetailPriceFieldDisabled = useMemo(
    () => priceType === ListingPriceTypeEnum.FreeForAll,
    [priceType]
  );

  // Checks if SN is valid, empty, or missing something.
  const isSerialNoValid = async (serialNo: string) => {
    setIsSNValidLoading(true);
    const response = unwrapResult(
      await dispatch(listingActions.isSerialNoExist(serialNo)).finally(() =>
        setIsSNValidLoading(false)
      )
    );
    // Intended strict boolean false checking;
    if (response?.success && response?.originalData?.is_exist === false) {
      return true;
    }
    return false;
  };

  const getSuppliers = debounce(async (keyword: string = '') => {
    dispatch(supplierActions.getSuppliersThunk({ keyword }));
  }, 500);

  const onSupplierInputChange = (text: string) => {
    setSupplierInput(text);
  };

  const onWarrantyInputChange = (text: string) => {
    setWarrantyInput(text);
  };

  const onChangePurchaseDate = (date: Date | null | string) => {
    if (typeof date === 'string') {
      date = new Date(date);
    }
    setPurchaseDate(date);
    setDateChanged(true);
  };

  useEffect(() => {
    if (dateChanged) {
      validateField('purchaseDate', purchaseDate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [purchaseDate, dateChanged]);

  const onSuggestedPriceClicked = (
    type: 'dealers_price' | 'retail_price' | 'dr'
  ) => {
    if (priceType === ListingPriceTypeEnum.FreeForAll) {
      return;
    }
    if (type === 'dealers_price' && isDealersPriceFieldDisabled) {
      return;
    }
    if (type === 'retail_price' && isRetailPriceFieldDisabled) {
      return;
    }
    if (type === 'dealers_price') {
      setValues((prev) => ({
        ...prev,
        dealers_price: state?.dealers_price
      }));
      return;
    }
    if (type === 'retail_price') {
      setValues((prev) => ({
        ...prev,
        retail_price: state?.retail_price
      }));
      return;
    }
    if (type === 'dr') {
      setValues((prev) => ({
        ...prev,
        retail_price: state?.retail_price
      }));
      return;
    }
  };

  const isSerialNoAlreadyExistingInTempList = () => {
    if (!hasNoSerial && values.serial_no && !isEmpty(recentListings)) {
      const duplicatedSN = recentListings?.find(
        (item) =>
          values?.serial_no?.toLowerCase()?.trim() ===
          item?.serial_no?.toLowerCase()?.trim()
      );

      if (duplicatedSN) {
        setValues({ ...values, serial_no: '' });
        setErrRecentListings((prev) => [
          ...prev,
          {
            ...duplicatedSN,
            temporary_error_string: localize.ERR_ADD_TEMP_LISTING_EXIST
          }
        ]);
        return `${localize.ERR_ADD_TEMP_LISTING_EXIST}: ${values.serial_no}`;
      }
    }
    return '';
  };

  const getCreateTempListingErrMsg = () => {
    const warrantyDuration = getWarrantyInDays(warrantyInput);

    if (recentListings.length === temporaryListingLimit) {
      // TODO: REMOVE LIMIT IF CONSUMABLE
      return localize.ERR_ADD_TEMP_LISTING_LIMIT;
    }
    if (!selectedSupplier) {
      return localize.ERR_ADD_TEMP_LISTING_SUPPLIER;
    }
    if (!selectedBranch && multiBranchFeat) {
      return localize.ERR_ADD_TEMP_LISTING_BRANCH;
    }
    if (!warrantyDuration) {
      return localize.ERR_FIELD_WARRANTY;
    }
    if (!purchaseDate) {
      return localize.ERR_FIELD_PURCHASE_DATE;
    }
    if (!drNo) {
      return localize.ERR_FIELD_DR_NO;
    }
    if (!isProductConsumable && !hasNoSerial && !values.serial_no) {
      return localize.ERR_INVALID_SERIAL_NO;
    }

    const doesSerialNoExistInTemporaryList = isSerialNoAlreadyExistingInTempList();
    if (doesSerialNoExistInTemporaryList) {
      return doesSerialNoExistInTemporaryList;
    }

    return '';
  };

  const onCreateItemWithSerialNo = async () => {
    setValues((prev) => ({ ...prev, serial_no: '' }));
    serialNoFieldRef?.current?.focus();

    const warrantyDuration = getWarrantyInDays(warrantyInput);
    const newProductListingData = {
      warranty_duration: warrantyDuration,
      product_id: state.id,
      category_id: state.category_id,
      serial_no: cleanSN(values.serial_no),
      supplier: selectedSupplier?.name,
      supplier_id: selectedSupplier?.id,
      dr_no: drNo,
      retail_price: Math.ceil(+values?.retail_price),
      dealers_price: Math.ceil(+values?.dealers_price),
      price_type: priceType,
      purchase_date: toUtcEquivalentDate(purchaseDate)?.toISOString(),
      branch_id: selectedBranch?.id,
      branch_name: selectedBranch?.branch_name
    };

    const isSerialNoValidInternal = await isSerialNoValid(values.serial_no);
    if (!isSerialNoValidInternal) {
      snackBar.show({
        severity: 'error',
        message: localize.ERR_INVALID_SERIAL_NO_API
      });
      setErrRecentListings((prev) => [
        ...prev,
        {
          ...newProductListingData,
          temporary_error_string: localize.ERR_INVALID_SERIAL_NO_API
        }
      ]);
      return;
    }

    const clonedRecentProducts = cloneDeep(recentListings);

    const isSnAlreadyExist = clonedRecentProducts.find(
      (recentProd) => recentProd.serial_no === newProductListingData?.serial_no
    );

    if (isSnAlreadyExist) {
      notifSound.error();
      snackBar.show({
        severity: 'error',
        message: `${localize.ERR_DUPLICATE_TEMP_LISTING_SN}`
      });
      setErrRecentListings((prev) => [
        ...prev,
        {
          ...newProductListingData,
          temporary_error_string: localize.ERR_DUPLICATE_TEMP_LISTING_SN
        }
      ]);
      return;
    }

    clonedRecentProducts.push(newProductListingData);
    setRecentListings(clonedRecentProducts);
    notifSound.success();
    snackBar.show({
      severity: 'success',
      message: `${localize.SUC_ADD_TEMP_LISTING}: ${newProductListingData.serial_no}`
    });
  };

  const onCreateItemWithoutSerialNo = () => {
    const warrantyDuration = getWarrantyInDays(warrantyInput);
    if (!isNormalInteger(values.quantity)) {
      setValues({ ...values, quantity: 1 });
      snackBar.show({
        severity: 'error',
        message: localize.ERR_INVALID_QUANTITY
      });
      return;
    }
    if (+values.quantity + +recentListings.length > temporaryListingLimit) {
      snackBar.show({
        severity: 'error',
        // TODO: REMOVE LIMIT IF CONSUMABLE
        message: `${localize.ERR_ADD_TEMP_LISTING_LIMIT} Limit: ${temporaryListingLimit}`
      });
      return;
    }
    const newProductListingDataArr = [];
    const timeStampNow = +new Date();
    for (let i = 0; i < values.quantity; i++) {
      const newProductListingData = {
        warranty_duration: warrantyDuration,
        product_id: state.id,
        dr_no: drNo,
        category_id: state.category_id,
        serial_no: `PCWSN-${+timeStampNow +
          getRandomUUIDv4Short() +
          i}`?.toUpperCase(),
        supplier: selectedSupplier?.name,
        supplier_id: selectedSupplier?.id,
        retail_price: Math.ceil(+values?.retail_price),
        dealers_price: Math.ceil(+values?.dealers_price),
        price_type: priceType,
        purchase_date: toUtcEquivalentDate(purchaseDate)?.toISOString(),
        branch_id: selectedBranch?.id,
        branch_name: selectedBranch?.branch_name
      };
      newProductListingDataArr.push(newProductListingData);
    }
    const mergedArray = recentListings.concat(newProductListingDataArr);
    setRecentListings(mergedArray);
    notifSound.success();
    snackBar.show({
      severity: 'success',
      message: `${localize.SUC_ADD_TEMP_LISTING}: ${values.quantity} items`
    });
    quantityFieldRef?.current?.focus();
  };

  const onCreateItemConsumable = () => {
    let existingIndex = -1;

    // When creating consumable listings just add quantity in consumable_qty instead of
    // creating each items repeatedly using for each
    const warrantyDuration = getWarrantyInDays(warrantyInput);
    if (!isNormalInteger(values.quantity)) {
      setValues({ ...values, quantity: 1 });
      snackBar.show({
        severity: 'error',
        message: localize.ERR_INVALID_QUANTITY
      });
      return;
    }
    const newPurchaseDate = toUtcEquivalentDate(purchaseDate)?.toISOString();
    const formattedPurchaseDate = formatDate(purchaseDate || '');
    const consumableItemSN = `Supplier(${
      selectedSupplier?.name
    }) PurchaseDate(${formattedPurchaseDate}) PID(${
      state?.id
    }) EncodedDate${dateToday('PP')}`;
    const newProductListingData: CreateListingProduct = {
      warranty_duration: warrantyDuration,
      product_id: state.id,
      category_id: state.category_id,
      serial_no: consumableItemSN,
      supplier: selectedSupplier?.name,
      supplier_id: selectedSupplier?.id,
      retail_price: Math.ceil(+values?.retail_price),
      dealers_price: Math.ceil(+values?.dealers_price),
      price_type: priceType,
      consumable_qty: values.quantity,
      purchase_date: newPurchaseDate,
      is_consumable: true
    };

    // If ID/SN of consumable item already exist (meaning, same purchase date and supplier). just add quantity
    const clonedRecentListings = cloneDeep(recentListings);
    existingIndex = clonedRecentListings?.findIndex(
      (x) => x.serial_no === consumableItemSN
    );
    if (existingIndex > -1) {
      if (clonedRecentListings[existingIndex]?.consumable_qty) {
        if (values.quantity > 1) {
          clonedRecentListings[existingIndex].consumable_qty =
            // @ts-ignore: Object is possibly 'null'. Intended.
            +clonedRecentListings[existingIndex].consumable_qty +
            +values.quantity;
        } else {
          // @ts-ignore: Object is possibly 'null'. Intended.
          ++clonedRecentListings[existingIndex].consumable_qty;
        }
      }
    }

    if (existingIndex > -1) {
      // clonedRecentTransactions.push(newTransactionListingData);
      setRecentListings(clonedRecentListings);
    } else {
      setRecentListings(recentListings.concat([newProductListingData]));
    }

    notifSound.success();
    snackBar.show({
      severity: 'success',
      message: `${localize.SUC_ADD_TEMP_LISTING}: ${values.quantity} items`
    });
    quantityFieldRef?.current?.focus();
  };

  const gross = useMemo(() => +values?.retail_price - +values?.dealers_price, [
    values
  ]);

  const onGrossAlert = () => {
    alertGlobal.show({
      title: 'Gross is negative or zero',
      subtitle: `Are you sure you want continue?`,
      buttons: [
        {
          text: 'Ok',
          onClick: () => onCreateTempListing(false),
          color: 'secondary'
        },
        {
          text: 'Cancel',
          onClick: () => alertGlobal.hide()
        }
      ]
    });
  };

  const onCreateTempListing = async (showGrossWarn: boolean = true) => {
    snackBar.hide();
    alertGlobal.hide();

    const errMsg = getCreateTempListingErrMsg();
    if (!selectedBranch && multiBranchFeat) {
      snackBar.show({
        severity: 'error',
        message: 'Please select branch',
        useSound: true
      });
      serialNoFieldRef?.current?.focus();
      return;
    }

    if (errMsg || !areFieldsValid()) {
      snackBar.show({
        severity: 'error',
        message: errMsg,
        useSound: true
      });
      serialNoFieldRef?.current?.focus();
      return;
    } else {
      handleSetDR();
      handleSetRecentDates();
      handleSetSuppliers();
      handleSetBranches();
    }
    if (gross <= 0 && showGrossWarn) {
      onGrossAlert();
      return;
    }
    if (isProductConsumable && !hasNoSerial && values.quantity) {
      onCreateItemConsumable();
      return;
    }
    if (!hasNoSerial && values?.serial_no) {
      onCreateItemWithSerialNo();
      return;
    }
    if (hasNoSerial && values.quantity) {
      onCreateItemWithoutSerialNo();
      return;
    }
    // DO WE EVEN NEED THIS?
    // if (!selectedBranch && multiBranchFeat) {
    //   return localize.ERR_ADD_TEMP_LISTING_BRANCH;
    // }

    //only call update dr if there is changes
    if (currrentDrNo !== drNo) {
      setCurrentDrNo(drNo); //replace current dr
    }

    snackBar.show({
      severity: 'error',
      message: localize.ERR_FIELDS
    });
  };

  const onCreateProductListings = async () => {
    if (errRecentListings.length > 0) {
      alert('Please clear all Invalid Serial Nos before proceeding');
      return;
    }

    const response = unwrapResult(
      await dispatch(
        listingActions.createListingThunk({
          update_main_product_srp: updateProductSrp || false,
          update_main_product_dp: updateProductDp || false,
          multipleListings: recentListings
        })
      )
    );
    if (response?.success && response?.message) {
      if (response?.originalData?.existingListing) {
        snackBar.show({ severity: 'success', message: response.message });
        const existingListing = response.originalData.existingListing.filter(
          (o1: CreateListingProduct) =>
            recentListings.filter((o2) => o1.serial_no === o2.serial_no)
        );
        setRecentListings(existingListing);
        return;
      }
      setRecentListings([]);
      notifSound.success();
      snackBar.show({ severity: 'success', message: response.message });
    } else {
      snackBar.show({ severity: 'error', message: response.message });
    }
  };

  const handleChangeFreebies = (event: FixMeLater) => {
    setPriceType(event.target.value);
    if (event.target.value === ListingPriceTypeEnum.FreeFromSupplier) {
      setValues({ ...values, dealers_price: '0' });
      setUpdateProductDp(false);
    }
    if (event.target.value === ListingPriceTypeEnum.FreeForAll) {
      setValues({ ...values, dealers_price: '0', retail_price: '0' });
      setUpdateProductSrp(false);
    }
  };

  const handleChange = (event: CustomInputEvent) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const onClearFields = () => {
    setValues({
      retail_price: '',
      dealers_price: '',
      serial_no: '',
      dr_no: drNo,
      quantity: 1
    });
  };

  const onClearSupplier = () => {
    setPurchaseDate(null);
    setDateChanged(true);
  };

  const onTickBox = (checked: boolean) => {
    setValues({ ...values, serial_no: '', quantity: 1 });
    setHasNoSerial(checked);
  };

  const onKeyPress = (e: CustomKeyboardEvent) => {
    if (e.key === 'Enter' && autoAddOnEnter) {
      onCreateTempListing();
    }
  };

  const onDisable = (checked: boolean) => {
    setAutoAddOnEnter(checked);
  };

  const onPressDeleteRecentItem = (i: number) => {
    const clonedRecentProducts = cloneDeep(recentListings);
    clonedRecentProducts.splice(i, 1);
    setRecentListings(clonedRecentProducts);
  };

  const onCopyAllSerialNo = () => {
    if (recentListings && !isEmpty(recentListings)) {
      const allSerialsNos = recentListings.reduce((a, c) => {
        return `${a}` + `   ${c.serial_no}`;
      }, '');
      copyToClipboard(allSerialsNos);
      return;
    }
    snackBar.show({
      severity: 'error',
      message: localize.ERR_RECENT_LISTING_EMPTY
    });
  };

  const quantityFieldLabel = useMemo(() => {
    let unit = '';
    if (isProductConsumable && state?.consumable_unit) {
      unit = ` - ${state?.consumable_unit}(s)`;
    }
    return `Quantity ${unit}`;
  }, [isProductConsumable, state]);

  useDebouncedEffect(() => getSuppliers(supplierInput), 500, [supplierInput]);

  useEffect(() => {
    getBranches();
    dispatch(listingActions.getRecentSupplierThunk());
    dispatch(listingActions.getRecentPurchaseDateThunk());
    dispatch(listingActions.getRecentDrNoThunk());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (state?.warranty_duration) {
      const x = warranties?.find((x) => x.value === state?.warranty_duration);
      if (x) {
        setSelectedWarranty(x);
      } else {
        setWarrantyInput(state.warranty_duration?.toString());
      }
    }
  }, [state.warranty_duration]);

  const shouldPromptAppear = useMemo(
    () => recentListings && recentListings?.length > 0,
    [recentListings]
  );

  usePrompt(
    `You still have ${recentListings?.length} unsaved item listing(s). Leaving will discard this`,
    shouldPromptAppear
  );

  return (
    <Page className={classes.root} title={`New Listing Item: ${state.name}`}>
      <Container maxWidth={false}>
        <Box mt={2}>
          <Card>
            <CardHeader
              title={`New Listing Item (${state.name})`}
              subheader={`The information of new listing inside ${state.name}`}
            />
            <Divider />
            <CardContent>
              <Typography className={classes.subHeader} variant="h6">
                General Information
              </Typography>
              <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    disabled
                    label="Category"
                    name="category"
                    onChange={handleChange}
                    required
                    value={state.category}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    disabled
                    label="Manufacturer"
                    name="manufacturer"
                    onChange={handleChange}
                    required
                    value={state.manufacturer}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  {hasNoSerial || isProductConsumable ? (
                    <TextField
                      inputRef={quantityFieldRef}
                      helperText={quantityHelperText}
                      fullWidth
                      label={quantityFieldLabel}
                      name="quantity"
                      onChange={handleChange}
                      onKeyDown={(e) => onKeyPress(e)}
                      required
                      type="number"
                      value={values.quantity}
                      variant="outlined"
                    />
                  ) : (
                    <TextField
                      error={errFields?.serial_no ? true : false}
                      helperText={errFields?.serial_no}
                      fullWidth
                      inputRef={serialNoFieldRef}
                      label="Serial no."
                      name="serial_no"
                      onKeyDown={(e) => onKeyPress(e)}
                      onChange={(e) => {
                        handleChange(e);
                        validateField(e?.target?.name, e?.target?.value);
                      }}
                      required
                      value={values.serial_no}
                      variant="outlined"
                    />
                  )}
                  {isProductConsumable ? null : (
                    <div>
                      <Typography variant="subtitle1">
                        Sample SNs: {state?.sample_serial_nos?.join(', ')}
                      </Typography>
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={(e, checked) => onTickBox(checked)}
                            name="checkedB"
                            color="primary"
                          />
                        }
                        label="Item has no serial number."
                      />
                    </div>
                  )}
                  {isProductConsumable ? null : (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={autoAddOnEnter}
                          onChange={(e, checked) => onDisable(checked)}
                          name="checkedC"
                          color="primary"
                        />
                      }
                      label="Auto add on scan of code"
                    />
                  )}
                </Grid>
              </Grid>
            </CardContent>
            <Divider />
            <CardContent>
              <Typography className={classes.subHeader} variant="h6">
                Pricing
              </Typography>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <FormControl
                    fullWidth
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel>Price Type</InputLabel>
                    <Select
                      value={priceType}
                      onChange={handleChangeFreebies}
                      label="Price Type"
                    >
                      {listingPriceTypeOptions?.map((item) => (
                        <MenuItem key={item?.value} value={item?.value}>
                          {item?.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    error={errFields?.dealers_price ? true : false}
                    helperText={errFields?.dealers_price}
                    fullWidth
                    required
                    disabled={isDealersPriceFieldDisabled}
                    type="number"
                    label="Dealers Price"
                    name="dealers_price"
                    onChange={(e) => {
                      handleChange(e);
                      validateField(e?.target?.name, e?.target?.value);
                    }}
                    value={values?.dealers_price ?? ''}
                    variant="outlined"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">₱</InputAdornment>
                      )
                    }}
                  />
                  <Typography
                    onClick={() => onSuggestedPriceClicked('dealers_price')}
                    className={classes.suggestedPriceText}
                    variant="subtitle1"
                  >
                    Suggested DP (Please double-check):{' '}
                    {formatCurrency(state?.dealers_price || 0)}
                  </Typography>
                  <CheckBoxLabel
                    label="Update DP of Main Product"
                    checked={updateProductDp}
                    onChange={(value) => setUpdateProductDp(value)}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    error={errFields?.retail_price ? true : false}
                    helperText={errFields?.retail_price}
                    fullWidth
                    required
                    type="number"
                    label="Retail Price"
                    name="retail_price"
                    disabled={isRetailPriceFieldDisabled}
                    onChange={(e) => {
                      handleChange(e);
                      validateField(e?.target?.name, e?.target?.value);
                    }}
                    value={values?.retail_price ?? ''}
                    variant="outlined"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">₱</InputAdornment>
                      )
                    }}
                  />
                  <Typography
                    onClick={() => onSuggestedPriceClicked('retail_price')}
                    className={classes.suggestedPriceText}
                    variant="subtitle1"
                  >
                    Suggested SRP (Please double-check):{' '}
                    {formatCurrency(state?.retail_price || 0)}
                  </Typography>
                  <CheckBoxLabel
                    label="Update SRP of all available"
                    checked={updateProductSrp}
                    onChange={(value) => setUpdateProductSrp(value)}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Typography
                    style={{
                      color: getGrossColor(
                        +values?.retail_price - +values?.dealers_price || 0
                      )
                    }}
                    className={classes.subHeader}
                    variant="h6"
                  >
                    {`Gross: ${formatCurrency(
                      +values?.retail_price - +values?.dealers_price
                    )}`}
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
            <Divider />
            <CardContent>
              <Typography className={classes.subHeader} variant="h6">
                Other Information
              </Typography>
              <Grid container spacing={3}>
                <Grid item md={6} xs={6}>
                  <Box>
                    {/* TODO: https://v4.mui.com/components/autocomplete/#FreeSoloCreateOptionDialog.tsx */}
                    <Autocomplete
                      value={selectedSupplier}
                      onChange={(_, newValue) => {
                        setSelectedSupplier(newValue);
                        validateField('supplier', newValue);
                      }}
                      inputValue={supplierInput}
                      onInputChange={(_, newInputValue) => {
                        onSupplierInputChange(newInputValue);
                      }}
                      noOptionsText={`"${supplierInput}" has not been found in suppliers. Please add it first`}
                      id="supplier"
                      options={suppliers}
                      getOptionLabel={(option) => option.name}
                      renderInput={(params) => (
                        <TextField
                          error={errFields?.supplier ? true : false}
                          helperText={errFields?.supplier}
                          {...params}
                          required
                          label="Supplier"
                          variant="outlined"
                          name="supplier"
                        />
                      )}
                    />
                    <div className={classes.drText}>
                      <Typography
                        className={classes.suggestedPriceText}
                        variant="subtitle1"
                      >
                        Recent picked suppliers:{' '}
                      </Typography>
                      {listingRecentSuppliers.map((item, index) => {
                        const displayedSupplier = JSON.parse(item);
                        return (
                          <React.Fragment key={index}>
                            <Typography
                              onClick={() => {
                                setSelectedSupplier(displayedSupplier);
                              }}
                              variant="subtitle1"
                            >
                              {displayedSupplier.name}
                            </Typography>
                            {index < listingRecentSuppliers.length - 1 &&
                              ',\u00A0'}{' '}
                          </React.Fragment>
                        );
                      })}
                    </div>

                    <Button
                      style={{ padding: 0, paddingTop: 5, paddingLeft: 5 }}
                      onClick={onOpenCreateSupplierDialog}
                      color="primary"
                      variant="text"
                    >
                      add a supplier
                    </Button>
                  </Box>
                </Grid>
                {multiBranchFeat ? (
                  <Grid item md={6} xs={12}>
                    <Box>
                      <Autocomplete
                        value={selectedBranch}
                        onChange={(_, newValue) => {
                          setSelectedBranch(newValue);
                        }}
                        inputValue={branchInputValue}
                        onInputChange={(_, newInputValue) => {
                          setBranchInputValue(newInputValue);
                        }}
                        noOptionsText={`"${branchInputValue}" has not been found in branches.`}
                        id="branch"
                        options={newBranchesOptions}
                        getOptionLabel={(option) => option.branch_name}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            required
                            label="Branch"
                            variant="outlined"
                          />
                        )}
                      />
                      <div className={classes.drText}>
                        {listingRecentBranches.length > 0 ? (
                          <Typography
                            className={classes.suggestedPriceText}
                            variant="subtitle1"
                          >
                            Recent picked branches:{' '}
                          </Typography>
                        ) : null}
                        {listingRecentBranches.map((item, index) => {
                          const displayedBranch = JSON.parse(item);
                          return (
                            <React.Fragment key={index}>
                              <Typography
                                onClick={() => {
                                  setSelectedBranch(displayedBranch);
                                }}
                                variant="subtitle1"
                              >
                                {displayedBranch.branch_name}
                              </Typography>
                              {index < listingRecentBranches.length - 1 &&
                                ', \u00A0'}{' '}
                            </React.Fragment>
                          );
                        })}
                      </div>
                    </Box>
                  </Grid>
                ) : null}
                <Grid item md={6} xs={12}>
                  <Autocomplete
                    freeSolo
                    value={selectedWarranty}
                    onChange={(_, newValue) => {
                      setSelectedWarranty(newValue);
                    }}
                    inputValue={warrantyInput}
                    onInputChange={(_, newInputValue) => {
                      onWarrantyInputChange(newInputValue);
                    }}
                    id="warranty"
                    options={warranties}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                      <TextField
                        error={errFields?.warranty ? true : false}
                        helperText={errFields?.warranty}
                        {...params}
                        required
                        label="Warranty Duration (days)"
                        variant="outlined"
                        name="warranty"
                      />
                    )}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <DatePicker
                    error={errFields?.purchaseDate ? true : false}
                    helperText={errFields?.purchaseDate}
                    fullWidth
                    required
                    format="MMMM dd, yyyy"
                    inputVariant="outlined"
                    label="Purchase Date on Supplier"
                    name="purchaseDate"
                    value={purchaseDate}
                    onChange={(date) => {
                      onChangePurchaseDate(date);
                    }}
                    animateYearScrolling
                    InputProps={{
                      endAdornment: (
                        <IconButton
                          size="small"
                          onClick={(e) => {
                            e.stopPropagation();
                            onClearSupplier();
                          }}
                        >
                          <ClearIcon fontSize="small" />
                        </IconButton>
                      )
                    }}
                  />
                  <div className={classes.drText}>
                    <Typography
                      className={classes.suggestedPriceText}
                      variant="subtitle1"
                    >
                      Recent Date Picked:{' '}
                    </Typography>
                    {listingRecentPurchaseDates.map((item, index) => {
                      const date = new Date(item);
                      const formattedDate = date.toLocaleDateString();
                      return (
                        <React.Fragment key={index}>
                          <Typography
                            onClick={() => {
                              onChangePurchaseDate(date);
                            }}
                            variant="subtitle1"
                          >
                            {formattedDate}
                          </Typography>
                          {index < listingRecentPurchaseDates.length - 1 &&
                            ',\u00A0'}{' '}
                        </React.Fragment>
                      );
                    })}
                  </div>
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    error={errFields?.dr_no ? true : false}
                    helperText={errFields?.dr_no}
                    fullWidth
                    required
                    label="DR No."
                    name="dr_no"
                    onChange={(e) => {
                      setDrNo(e?.target?.value?.toUpperCase());
                      validateField(e?.target?.name, e?.target?.value);
                    }}
                    value={drNo ?? ''}
                    variant="outlined"
                  />
                  <div className={classes.drText}>
                    <Typography
                      className={classes.suggestedPriceText}
                      variant="subtitle1"
                    >
                      Recent DR Nos.:{' '}
                    </Typography>
                    {listingDRNoData.map((item, index) => (
                      <React.Fragment key={index}>
                        <Typography
                          onClick={() => setDrNo(item)}
                          variant="subtitle1"
                        >
                          {item}
                        </Typography>
                        {index < listingDRNoData.length - 1 && ',\u00A0'}{' '}
                      </React.Fragment>
                    ))}
                  </div>
                </Grid>
              </Grid>
            </CardContent>
            <Divider />
            <Box display="flex" justifyContent="space-between" p={2}>
              <Button
                startIcon={<ClearIcon />}
                onClick={onClearFields}
                color="secondary"
              >
                Clear
              </Button>
              <LoadingButton
                startIcon={<SaveIcon />}
                onClick={() => {
                  onCreateTempListing();
                }}
                loading={isSNValidLoading}
                color="primary"
                variant="contained"
                title="Add on temporary listings"
              />
            </Box>
          </Card>
        </Box>
        {!isEmpty(recentListings) ? (
          <Button
            style={{ marginTop: 18 }}
            startIcon={<FileCopyIcon />}
            onClick={onCopyAllSerialNo}
            variant="contained"
          >
            Copy All Serial Numbers
          </Button>
        ) : null}

        <ListingAddListError
          listingErrorList={errRecentListings}
          onClearAll={() => setErrRecentListings([])}
          onRemove={(index) =>
            setErrRecentListings((prev) => prev.filter((_, i) => i !== index))
          }
        />

        <TemporaryListings
          product={state}
          subHeader={`Sample SNs: ${state?.sample_serial_nos?.join(', ')}`}
          recentListings={recentListings}
          onPressDeleteRecentItem={onPressDeleteRecentItem}
        />

        <Box paddingBottom={2} display="flex" justifyContent="flex-end">
          <Button
            startIcon={<SaveIcon />}
            onClick={onCreateProductListings}
            color="primary"
            variant="contained"
          >
            Add temporary listings to database
          </Button>
        </Box>
      </Container>
      <FormAddDialog
        loading={addSupplierLoading}
        fieldName="Supplier"
        title="Create Supplier"
        isVisible={isCreateSupplierVisible}
        subTitle="Input Supplier"
        onAddPress={onAddSupplierOnModalPress}
        handleClose={onCloseCreateSupplierDialog}
      />
    </Page>
  );
};

export default AddProductListingView;

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Search as SearchIcon } from 'react-feather';
import { cloneDeep, debounce, isEmpty } from 'lodash';
import Alert from '@material-ui/lab/Alert';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import ClearIcon from '@material-ui/icons/Clear';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Container,
  IconButton,
  Grid,
  TextField,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  FormControlLabel,
  Checkbox,
  Divider,
  Typography,
  InputAdornment,
  CircularProgress,
  SvgIcon
} from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useLocation, useNavigate } from 'react-router-dom';
import { LoadingButton, Page } from 'src/components';
import { localize } from 'src/constants';
import {
  AddTempTransactionProp,
  CustomInputEvent,
  CustomKeyboardEvent,
  ErrorSerialNoList,
  GetListingViaSerialRequest,
  ProductConsumable,
  ProductWithSerialNo,
  Transaction,
  TransactionInputField
} from 'src/types';
import { cleanSN, formatCurrency, isEmptyOrSpaces } from 'src/utils';
import { useDebouncedEffect, useSnackBar } from 'src/hooks';
import { slices, useAppDispatch, useAppSelector } from 'src/redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { useRef } from 'react';
import { Autocomplete } from '@material-ui/lab';
import { theme } from 'src/theme';
import { BranchListDropDown } from 'src/components/dropdown';
import { NonSerializeProductDropdown } from 'src/components/dropdown/NonSerializeDropdown';
import { sortProductsByCategory } from 'src/utils/categories/sortByCategory';
import { TransactListError } from '../components/TransactListError';

const useStyles = makeStyles((theme) => ({
  root: {},
  divider: {
    margin: theme.spacing(1),
    marginTop: theme.spacing(2)
  },
  consumableField: {
    display: 'flex',
    flexDirection: 'row'
  },
  consumableSerial: {
    fontSize: 10,
    fontWeight: 500
  },
  extraInfo: {
    margin: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end'
  }
}));

const { actions: listingActions } = slices.listing;
const {
  actions: transactionActions,
  selectors: transactionSelectors
} = slices.transaction;
const { actions: productActions } = slices.product;

const AddTransactionView = () => {
  const classes = useStyles();
  const snackBar = useSnackBar();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const isCreateTransactionThunkLoading = useAppSelector(
    transactionSelectors.isCreateTransactionLoading
  );
  const navigate = useNavigate();
  const serialNoTextFieldRef = useRef<any>(null);
  const { state }: any = location;

  const [isLoading, setIsLoading] = useState(false);
  const [navigateTransactionDetails, setNavigateTransactionDetails] = useState(
    true
  );
  const [loadingListingInfo, setLoadingListing] = useState<boolean>(false);
  const [recentTransactions, setRecentTransactions] = useState<Transaction[]>(
    []
  );
  const [invalidItems, setInvalidItems] = useState<Transaction[]>([]);
  const [serialNo, setSerialNo] = useState('');
  const [errorSerialNos, setErrorSerialNos] = useState<ErrorSerialNoList>([]);
  const [productNameInput, setProductNameInput] = useState('');
  const [isProductsLoading, setIsProductsLoading] = useState(false);
  const [products, setProducts] = useState<ProductWithSerialNo[]>([]);
  const [
    selectedProduct,
    setSelectedProduct
  ] = useState<ProductWithSerialNo | null>(null);
  const [transactionRemarks, setTransactionRemarks] = useState<string>();

  const [consumableInput, setConsumableInput] = useState('');
  const [isConsumablesLoading, setIsConsumablesLoading] = useState(false);
  const [consumables, setConsumables] = useState<ProductConsumable[]>([]);
  const [
    selectedConsumable,
    setSelectedConsumable
  ] = useState<ProductConsumable | null>(null);
  const [consumableQty, setConsumableQty] = useState<number>();
  const [selectedBranchIds, setSelectedBranchIds] = useState<number[]>();

  const clearRecentTransactions = () => {
    setRecentTransactions([]);
  };

  const onClearAll = () => {
    setErrorSerialNos([]);
  };

  const fetchListingViaParam = async (val: GetListingViaSerialRequest) => {
    const response = unwrapResult(
      await dispatch(listingActions.getListingViaSNThunk(val))
    );
    return response;
  };

  const onRemoveErrorItem = (index: number) => {
    setErrorSerialNos((prev) => prev.filter((_, i) => i !== index));
  };

  const onResyncErrorItem = debounce((index: number) => {
    const errorItem = errorSerialNos?.[index];
    if (errorItem?.branch_id) {
      handleTransaction(errorItem.serial_no, errorItem.branch_id);
      return;
    } else {
      return;
    }
  }, 500);

  const handleTransaction = async (
    serial_no = cleanSN(serialNo),
    branch_id = selectedBranchIds?.[0]
  ) => {
    const requestParams = {
      serial_no,
      branch_id
    };

    try {
      if (recentTransactions.find((prod) => prod.serial_no === serial_no)) {
        snackBar.show({
          severity: 'error',
          message: 'Item is already added on the list.',
          useSound: true
        });
        return;
      }

      if (!serial_no) {
        snackBar.show({
          severity: 'error',
          message: 'Please input a serial number',
          useSound: true
        });
        return;
      }

      if (!branch_id) {
        snackBar.show({
          severity: 'error',
          message: 'Please select a branch',
          useSound: true
        });
        return;
      }

      const response = await fetchListingViaParam({
        serial_no: serial_no,
        branch_ids: [branch_id]
      });

      const message = response?.message;
      const listingData = response?.originalData?.listing ?? null;

      if (listingData) {
        snackBar.show({
          severity: 'success',
          message: 'Product found and added to the list',
          useSound: true
        });
        addTransactionToTempList({
          serialNoArg: listingData.serial_no,
          productNameArg: listingData?.product_name,
          amount: listingData?.retail_price || 0,
          category_name: listingData?.category_name
        });

        // If added sn is already on error list, remove it.
        setErrorSerialNos((prev) =>
          prev?.filter((i) => i?.serial_no !== serial_no)
        );
        return;
      } else {
        snackBar.show({
          severity: 'error',
          message: message,
          useSound: true
        });

        // Check if new item for error list already exist.
        const itemAlreadyExistInErrSerialNos = errorSerialNos.find(
          (x) =>
            x?.serial_no === requestParams?.serial_no &&
            x?.branch_id === requestParams?.branch_id
        );
        if (itemAlreadyExistInErrSerialNos) {
          setErrorSerialNos((prev) => {
            const newErrorSerialNos = prev.map((x) => {
              if (x?.serial_no === serial_no) {
                return { ...x, message: message };
              }
              return x;
            });
            return newErrorSerialNos;
          });
          return;
        }
        setErrorSerialNos((prev) => [
          ...prev,
          { ...requestParams, message: message }
        ]);
      }
    } catch (error) {
      setErrorSerialNos((prev) => [
        ...prev,
        { ...requestParams, message: 'Something went wrong. Try again later' }
      ]);
      console.error(error);
      return;
    }
  };

  const consumableOptionLabel = useCallback(
    (option: ProductConsumable) => {
      return (
        <div>
          <Typography>{`${option?.product_name} (per ${option?.consumable_unit}) - ${option?.consumable_qty} stocks left`}</Typography>
          <Typography
            className={classes.consumableSerial}
          >{`${option?.serial_no}`}</Typography>
        </div>
      );
    },
    [classes.consumableSerial]
  );

  const onCreateTransaction = async () => {
    if (isLoading || isCreateTransactionThunkLoading) {
      return;
    }
    const response = unwrapResult(
      await dispatch(
        transactionActions.createTransactionThunk({
          multipleListings: recentTransactions,
          transaction_remarks: transactionRemarks
        })
      ).finally(() => setIsLoading(false))
    );
    if (response?.success && response?.message) {
      clearRecentTransactions();
      snackBar.show({
        severity: 'success',
        message: response.message
      });
      if (navigateTransactionDetails) {
        navigateToTransaction(response?.originalData?.transaction_no);
      }
    } else if (
      !response?.success &&
      !isEmpty(response?.originalData?.invalid_items)
    ) {
      setInvalidItems(response?.originalData?.invalid_items ?? []);
      snackBar.show({
        severity: 'error',
        message: response.message
      });
    } else {
      snackBar.show({
        severity: 'error',
        message: response.message
      });
    }
  };

  const onCreateTransactionDebounce = debounce(onCreateTransaction, 1000);

  const getListingInfoViaSerial = async () => {
    if (!serialNo) {
      return;
    }

    setLoadingListing(true);
    const response = unwrapResult(
      await dispatch(
        listingActions.getListingViaSNThunk({
          serial_no: cleanSN(serialNo),
          branch_ids: selectedBranchIds
        })
      ).finally(() => setLoadingListing(false))
    );

    return response;
  };

  const isSerialNumberValid = () => {
    const serial_no = serialNo?.trim();
    setSerialNo('');

    if (isEmptyOrSpaces(serial_no)) {
      snackBar.show({
        severity: 'error',
        message: localize.ERR_INVALID_SERIAL_NO
      });
      setSerialNo('');
      return false;
    }

    if (serial_no && !isEmpty(recentTransactions)) {
      const exist = recentTransactions?.find((x) => x.serial_no === serial_no);
      if (exist) {
        snackBar.show({
          severity: 'error',
          message: `${localize.ERR_ADD_TEMP_LISTING_EXIST} ${serial_no}`
        });
        setSerialNo('');
        return false;
      }
    }

    if (!state?.id) {
      snackBar.show({
        severity: 'error',
        message: localize.ERR_NO_TRANSACTION
      });
      return false;
    }

    return true;
  };

  const addTransactionToTempList = (param: AddTempTransactionProp) => {
    // Note: on amount, please compute it before passing it here in `addTransactionToTempList` function

    let existingIndex = -1;
    const {
      serialNoArg,
      productNameArg,
      quantity,
      product_id,
      is_consumable,
      listing_id,
      consumable_unit,
      amount,
      category_name
    } = param;

    const newTransactionListingData: Transaction = {
      customer_id: state.id,
      serial_no: serialNoArg,
      product_name: productNameArg,
      quantity,
      product_id,
      is_consumable,
      listing_id,
      consumable_unit,
      amount,
      category_name
    };

    const clonedRecentTransactions = cloneDeep(recentTransactions);

    // TODO: If product_id already exist. just add quantity
    // if product is consumable and it already product_id exist on recent transactions. just add quantity
    if (product_id && is_consumable && quantity) {
      existingIndex = clonedRecentTransactions?.findIndex(
        (x) => x.product_id === product_id
      );
      if (existingIndex > -1) {
        if (clonedRecentTransactions[existingIndex]?.quantity) {
          if (quantity > 0) {
            clonedRecentTransactions[existingIndex].quantity =
              // @ts-ignore: Object is possibly 'null'. Intended.
              clonedRecentTransactions[existingIndex].quantity + quantity;
          } else {
            // @ts-ignore: Object is possibly 'null'. Intended.
            ++clonedRecentTransactions[existingIndex].quantity;
          }
        }
      }
    }

    if (existingIndex <= -1) {
      clonedRecentTransactions.push(newTransactionListingData);
    }
    const sortedRecentTransaction = sortProductsByCategory(
      clonedRecentTransactions
    );
    setRecentTransactions(sortedRecentTransaction);
    snackBar.show({
      severity: 'success',
      message: `${localize.SUC_ADD_TEMP_TRANSACT}: ${newTransactionListingData?.product_name}`
    });
    setSerialNo('');
    setSelectedProduct(null);
    setProductNameInput('');
    setSelectedConsumable(null);
    setConsumableInput('');
    setConsumableQty(undefined);
    serialNoTextFieldRef?.current?.focus();
  };

  const onAddToTempTransaction = async () => {
    // If a product with SN is selected / inputted
    if (selectedProduct) {
      addTransactionToTempList({
        serialNoArg: selectedProduct?.serial_no,
        productNameArg: selectedProduct?.product_name,
        amount: selectedProduct?.retail_price || 0,
        category_name: selectedProduct?.category_name
      });
      return;
    }

    // If SN is selected / inputted
    if (serialNo) {
      if (loadingListingInfo) {
        return;
      }
      if (!isSerialNumberValid()) {
        return;
      }

      const response = await getListingInfoViaSerial();
      const listingData = response?.originalData.listing;

      if (!listingData) {
        return;
      }
      addTransactionToTempList({
        serialNoArg: serialNo,
        productNameArg: listingData?.product_name,
        amount: listingData?.retail_price || 0,
        category_name: listingData?.category_name
      });
      return;
    }

    // If consumable is selected / inputted
    if (selectedConsumable) {
      // product id and quantity is neede because in this product type. We have no serial no.
      addTransactionToTempList({
        serialNoArg: selectedConsumable?.serial_no,
        productNameArg: selectedConsumable?.product_name,
        quantity: consumableQty || 1,
        product_id: selectedConsumable?.product_id,
        is_consumable: true,
        listing_id: selectedConsumable?.listing_id,
        consumable_unit: selectedConsumable?.consumable_unit,
        amount: (selectedConsumable?.retail_price || 0) * (consumableQty || 1),
        category_name: selectedConsumable?.category_name
      });
      return;
    }
  };

  const handleOnRemarksChange = (event: CustomInputEvent) => {
    setTransactionRemarks(event.target.value);
  };

  const onChangeSerialNo = (serial_no: string) => {
    setProductNameInput('');
    setConsumableInput('');
    setConsumableQty(undefined);
    setSerialNo(serial_no);
  };

  const keyPress = (e: CustomKeyboardEvent) => {
    if (e.key === 'Enter') {
      handleTransaction();
      setSerialNo('');
      serialNoTextFieldRef.current.focus();
    }
  };

  const onPressDeleteRecentItem = (i: number) => {
    const clonedRecentTransactions = cloneDeep(recentTransactions);
    clonedRecentTransactions.splice(i, 1);
    setRecentTransactions(clonedRecentTransactions);
  };

  const navigateToTransaction = (transaction_no: string) => {
    navigate(`/app/transaction/${transaction_no}`);
  };

  const onGetConsumablesViaKeyword = useCallback(
    async (branchIds?: number[]) => {
      setIsConsumablesLoading(true);
      const response = unwrapResult(
        await dispatch(
          productActions.getProductConsumablesThunk({
            keyword: consumableInput,
            branch_id: branchIds || selectedBranchIds
          })
        ).finally(() => setIsConsumablesLoading(false))
      );

      if (response?.success) {
        setConsumables(response?.originalData?.products || []);
      }
    },
    [consumableInput, dispatch, selectedBranchIds]
  );

  const onGetProductsViaKeyword = useCallback(
    async (branchIds?: number[]) => {
      setIsProductsLoading(true);
      const clonedRecentTransactions = recentTransactions;
      const arrOfSerialTempAdded = clonedRecentTransactions?.map(
        (x) => x.serial_no || ''
      );
      const response = unwrapResult(
        await dispatch(
          productActions.getProductsWithSerialThunk({
            keyword: productNameInput,
            except_serial_no: arrOfSerialTempAdded,
            branch_ids: branchIds || selectedBranchIds
          })
        ).finally(() => setIsProductsLoading(false))
      );

      if (response?.success) {
        setProducts(response?.originalData?.products || []);
      }
    },
    [dispatch, productNameInput, recentTransactions, selectedBranchIds]
  );

  const onProductNameInputChange = (text?: string) => {
    setSerialNo('');
    setProductNameInput(text || '');
  };

  // Disable field that is not related to current field.
  const isFieldDisabled = useCallback(
    (field: TransactionInputField) => {
      switch (field) {
        case 'serialized':
          return consumableInput ||
            selectedConsumable ||
            consumableQty ||
            loadingListingInfo ||
            productNameInput ||
            !selectedBranchIds
            ? true
            : false;
        case 'non-sn':
          return consumableInput ||
            selectedConsumable ||
            consumableQty ||
            serialNo ||
            !selectedBranchIds
            ? true
            : false;
        case 'consumable':
          return productNameInput || serialNo || !selectedBranchIds
            ? true
            : false;
        default:
          break;
      }
    },
    [
      consumableInput,
      consumableQty,
      loadingListingInfo,
      productNameInput,
      selectedBranchIds,
      selectedConsumable,
      serialNo
    ]
  );

  const totalPrice = useMemo(() => {
    const totalStr =
      recentTransactions?.reduce(
        (accumulator, item) => accumulator + (item?.amount || 0),
        0
      ) || 0;
    return totalStr;
  }, [recentTransactions]);

  // Clear field that is not related to current field onFocus.
  const onFocusOfField = (field: TransactionInputField) => {
    switch (field) {
      case 'serialized':
        setProductNameInput('');
        setSelectedProduct(null);

        setConsumableInput('');
        setSelectedConsumable(null);
        setConsumableQty(undefined);
        break;
      case 'non-sn':
        setSerialNo('');
        setConsumableInput('');
        setSelectedConsumable(null);
        setConsumableQty(undefined);
        break;
      case 'consumable':
        setProductNameInput('');
        setSelectedProduct(null);
        setSerialNo('');
        break;
      default:
        break;
    }
  };

  const onChangeSelectedConsumable = (newValue: ProductConsumable | null) => {
    if (!consumableQty && newValue) {
      setConsumableQty(1);
    }
    if (!newValue) {
      setConsumableQty(undefined);
    }
    setSelectedConsumable(newValue);
  };

  const transactionItemQty = useCallback((item: Transaction) => {
    if (item?.consumable_unit) {
      return `${item?.quantity} ${item?.consumable_unit}(s)`;
    }
    return `${item?.quantity || 1}`;
  }, []);

  const onClearFields = () => {
    setSerialNo('');
    setSelectedProduct(null);
    setSelectedConsumable(null);
    setConsumableQty(0);
    setConsumableInput('');
  };

  const onChangeBranch = useCallback(
    (branchIds?: number[]) => {
      onClearFields();
      setSelectedBranchIds(branchIds);
      onGetConsumablesViaKeyword(branchIds);
      onGetProductsViaKeyword(branchIds);
    },
    [onGetConsumablesViaKeyword, onGetProductsViaKeyword]
  );

  useEffect(() => {
    if (!loadingListingInfo && serialNoTextFieldRef.current) {
      serialNoTextFieldRef.current.focus();
    }
  }, [loadingListingInfo]);

  useEffect(() => {
    //if branch ids is empty clear this fields
    if (isEmpty(selectedBranchIds)) {
      onClearFields();
    }
  }, [selectedBranchIds]);

  useDebouncedEffect(onGetProductsViaKeyword, 500, [productNameInput]);
  useDebouncedEffect(onGetConsumablesViaKeyword, 500, [consumableInput]);

  return (
    <Page className={classes.root} title="New Transaction">
      <Container maxWidth={false}>
        <Box mt={2}>
          <Card>
            <CardHeader
              title="New Transaction"
              subheader="The information about new transaction."
            />
            <Divider />
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={12} xs={12}>
                  <TextField
                    disabled
                    fullWidth
                    required
                    label="Customer"
                    variant="outlined"
                    value={
                      state ? `${state.first_name} ${state.last_name}` : ''
                    }
                  />
                </Grid>
                <Grid item md={12}>
                  <BranchListDropDown
                    onHandleBranchChange={(branchIds?: number[]) =>
                      onChangeBranch(branchIds)
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    onFocus={() => onFocusOfField('serialized')}
                    fullWidth
                    helperText="e.g. GPU, CPU, RAM"
                    label="Serialized - Serial no."
                    name="serial_no"
                    onKeyDown={keyPress}
                    onChange={(e) => onChangeSerialNo(e.target.value)}
                    value={serialNo}
                    variant="outlined"
                    inputRef={serialNoTextFieldRef}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          {loadingListingInfo ? (
                            <CircularProgress size={20} />
                          ) : (
                            <SvgIcon fontSize="small" color="action">
                              <SearchIcon />
                            </SvgIcon>
                          )}
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <IconButton
                          size="small"
                          onClick={() => onChangeSerialNo('')}
                        >
                          <ClearIcon fontSize="small" />
                        </IconButton>
                      )
                    }}
                  />
                  <TransactListError
                    onClearAll={onClearAll}
                    onRetry={onResyncErrorItem}
                    onRemove={onRemoveErrorItem}
                    transactionErrorList={errorSerialNos}
                  />
                </Grid>
                <Grid item xs={12}>
                  <NonSerializeProductDropdown
                    selectedProduct={selectedProduct}
                    setSelectedProduct={(product: ProductWithSerialNo | null) =>
                      setSelectedProduct(product)
                    }
                    productOptions={products}
                    productLoading={isProductsLoading}
                    isDisabled={() => isFieldDisabled('non-sn') || false}
                    onProdInputChange={(prodName?: string) => {
                      onProductNameInputChange(prodName);
                    }}
                    onFocus={() => onFocusOfField('non-sn')}
                  />
                </Grid>
                <Grid className={classes.consumableField} item xs={12}>
                  <TextField
                    style={{ width: 150 }}
                    type="number"
                    label="quantity"
                    name="consumable_qty"
                    variant="outlined"
                    onFocus={() => onFocusOfField('consumable')}
                    value={consumableQty || ''}
                    disabled={
                      isFieldDisabled('consumable') || !selectedConsumable
                    }
                    onChange={(e) => setConsumableQty(+e.target.value)}
                  />
                  <Autocomplete
                    fullWidth
                    onFocus={() => onFocusOfField('consumable')}
                    style={{ marginLeft: theme.spacing(1) }}
                    loading={isConsumablesLoading}
                    disabled={isFieldDisabled('consumable')}
                    value={selectedConsumable}
                    onChange={(_, newValue) =>
                      onChangeSelectedConsumable(newValue)
                    }
                    inputValue={consumableInput}
                    onInputChange={(_, newInputValue) =>
                      setConsumableInput(newInputValue)
                    }
                    id="consumable_name"
                    options={consumables}
                    renderOption={consumableOptionLabel}
                    getOptionLabel={(option) => `${option?.product_name}`}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="consumable_name"
                        label="Consumable - Product Name"
                        variant="outlined"
                        value={consumableInput}
                        helperText="e.g. Cables, RJ45"
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </CardContent>
            <Box
              alignItems="center"
              display="flex"
              justifyContent="flex-end"
              p={2}
            >
              <LoadingButton
                color="primary"
                variant="contained"
                loading={loadingListingInfo}
                title="Add Item"
                onClick={onAddToTempTransaction}
              />
            </Box>
          </Card>
        </Box>
        <Card>
          <CardHeader title="Transaction Remarks" />
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  multiline
                  rows={4}
                  label="Notes about Transaction"
                  name="transaction_remarks"
                  onChange={handleOnRemarksChange}
                  value={transactionRemarks}
                  variant="outlined"
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <Box marginY={2}>
          <Card>
            {!isEmpty(invalidItems)
              ? invalidItems.map((item, i) => (
                  <Alert key={item?.serial_no ?? i} severity="warning">
                    {`${item?.serial_no ??
                      item?.product_name} is out of stock or does not exist or already purchased`}
                  </Alert>
                ))
              : null}
            <CardHeader title="Added Products" />
            <Divider />
            <PerfectScrollbar>
              <Box>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Qty.</TableCell>
                      <TableCell>Category</TableCell>
                      <TableCell>Name</TableCell>
                      <TableCell>Identification / Serial No.</TableCell>
                      <TableCell>Amount</TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {recentTransactions.map((item, i) => (
                      <TableRow hover key={i}>
                        <TableCell>{transactionItemQty(item)}</TableCell>
                        <TableCell>{item.category_name}</TableCell>
                        <TableCell>{item.product_name}</TableCell>
                        <TableCell>{item.serial_no}</TableCell>
                        <TableCell>{formatCurrency(item?.amount)}</TableCell>
                        <TableCell>
                          <IconButton
                            onClick={() => onPressDeleteRecentItem(i)}
                          >
                            <DeleteForeverIcon color="secondary" />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                {recentTransactions?.length > 0 ? (
                  <div className={classes.extraInfo}>
                    <Typography variant="h4">
                      TOTAL PRICE:{formatCurrency(totalPrice)}
                    </Typography>
                  </div>
                ) : null}
              </Box>
            </PerfectScrollbar>
          </Card>
        </Box>
        <Box paddingBottom={2} display="flex" justifyContent="flex-end">
          <FormControlLabel
            control={
              <Checkbox
                checked={navigateTransactionDetails}
                name="navigateTransactionDetails"
                color="primary"
                onChange={(_, s) => setNavigateTransactionDetails(s)}
              />
            }
            label="Navigate to Transaction Details After Saving"
          />

          {/* THIS IS OVERKILL BECAUSE WE DONT KNOW WHY.. TRANSACTION IS STILL BEING DUPLICATED */}
          {isLoading || isCreateTransactionThunkLoading ? (
            <LoadingButton
              color="primary"
              loading={true}
              disabled={true}
              variant="contained"
              title="Transaction is being processed..."
              onClick={onCreateTransactionDebounce}
            />
          ) : (
            <LoadingButton
              color="primary"
              variant="contained"
              disabled={isLoading || isCreateTransactionThunkLoading}
              loading={isLoading || isCreateTransactionThunkLoading}
              title="Add transaction to database"
              onClick={onCreateTransactionDebounce}
            />
          )}
        </Box>
      </Container>
    </Page>
  );
};

export default AddTransactionView;

import { Card, CardContent, TextField, Typography } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { LoaderBar } from 'src/components';
import { slices, useAppDispatch, useAppSelector } from 'src/redux';
import { BundleDetailsV2 } from 'src/redux/slices/pc-bundles/types';
import { Manufacturer } from 'src/constants/inventory';

const {
  selectors: manufacturerSelectors,
  actions: manufacturerActions
} = slices.manufacturer;

const {
  selectors: pcBundleSelectors,
  actions: pcBundleActions
} = slices.pcBundles;

interface Props {
  bundle?: BundleDetailsV2;
}

export const SelectManufacturer: FC<Props> = ({ bundle }) => {
  const dispatch = useAppDispatch();
  const manufacturerID = useAppSelector(pcBundleSelectors.selectManufacturer);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [manufacturerVal, setManufacturerVal] = useState<Manufacturer | null>(
    null
  );

  const manufacturers = useAppSelector(
    manufacturerSelectors.selectManufacturers
  );

  const onChangeManufacturer = useCallback(
    (e: any, value: Manufacturer | null) => {
      value && dispatch(pcBundleActions.setManufacturer(value.id));
    },
    [dispatch]
  );

  const getManufacturers = useCallback(async () => {
    setIsLoading(true);
    await dispatch(
      manufacturerActions.getManufacturersThunk({ keyword: '' })
    ).finally(() => setIsLoading(false));
  }, [dispatch]);

  useEffect(() => {
    getManufacturers();
  }, [getManufacturers]);

  useEffect(() => {
    bundle?.manufacturer &&
      dispatch(pcBundleActions.setManufacturer(bundle?.manufacturer?.id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bundle]);

  useEffect(() => {
    const manufacturer = manufacturers.find((i) => i.id === manufacturerID);
    setManufacturerVal(manufacturer || null);
  }, [manufacturerID, manufacturers]);

  return (
    <Card
      style={{
        marginTop: '1em'
      }}
    >
      <CardContent>
        {isLoading ? (
          <LoaderBar isLoading={isLoading} />
        ) : (
          <>
            <Typography>Select Bundle Powered by</Typography>
            <Autocomplete
              options={manufacturers}
              value={manufacturerVal}
              getOptionLabel={(option) => option.name}
              onChange={onChangeManufacturer}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" />
              )}
              getOptionSelected={(option, value) => option.id === value.id}
            />
          </>
        )}
      </CardContent>
    </Card>
  );
};
export default SelectManufacturer;

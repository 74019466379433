import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  FormHelperText,
  Grid,
  TextField,
  Typography
} from '@material-ui/core';
import React, { FC, useCallback, useEffect, useState } from 'react';
import AddIconAdornment from './AddIconAdornment';
import {
  BundleComponent,
  BundleDetailsV2
} from 'src/redux/slices/pc-bundles/types';
import { slices, useAppDispatch, useAppSelector } from 'src/redux';
import { unwrapResult } from '@reduxjs/toolkit';
import useUserInfo from 'src/hooks/user/use-user-info';
import { BundleStatusEnum, ListingStatusEnum } from 'src/enums';
import ClearIcon from '@material-ui/icons/Clear';
import { Alert } from '@material-ui/lab';

interface Props {
  bundle?: BundleDetailsV2;
}

const { actions: listingActions } = slices.listing;

const {
  selectors: pcBundleSelectors,
  actions: pcBundleActions
} = slices.pcBundles;

export const BundleComponents: FC<Props> = ({ bundle }) => {
  const dispatch = useAppDispatch();
  const { allBranchIDs } = useUserInfo();

  const bundleProducts = useAppSelector(pcBundleSelectors.selectProducts);

  const [serialVal, setSerialVal] = useState<string>('');
  const [errSerial, setErrSerial] = useState<string>('');
  const [isItemCore, setIsItemCore] = useState<boolean>(false);
  const [isBundleUnpublished, setIsBundleUnpublished] = useState<boolean>(
    false
  );
  const [components, setComponents] = useState<BundleComponent[]>([]);

  const removeSerial = useCallback(
    (v: string) => {
      let updatedComponents = components.filter((i) => i.serial_no !== v);
      setComponents(updatedComponents);
      dispatch(pcBundleActions.setProducts(updatedComponents));
    },
    [components, dispatch]
  );

  const addSerial = useCallback(async () => {
    let err = '';
    let trimSerial = serialVal.trim();

    if (!trimSerial) {
      setErrSerial('Invalid Serial Value');
      return;
    }

    const response = unwrapResult(
      await dispatch(
        listingActions.getListingViaSNThunk({
          serial_no: serialVal,
          branch_ids: allBranchIDs
        })
      )
    );

    const listing = response.originalData.listing;

    if (listing) {
      if (components.find((i) => i.serial_no === trimSerial)) {
        // Check if serial is on list
        err = 'Serial already on list';
      } else if (listing.status !== ListingStatusEnum.Available) {
        // Check if serial is available
        err = 'Serial not available';
      }

      if (err) {
        setErrSerial(err);
        return;
      }

      const { product_name: product, category_name: category } = listing;

      const updatedComponents = [
        ...components,
        {
          serial_no: serialVal,
          is_core_component: isItemCore,
          product,
          category
        }
      ];

      dispatch(pcBundleActions.setProducts(updatedComponents));
      setSerialVal('');
    } else {
      setErrSerial(response.message);
      return;
    }
  }, [allBranchIDs, dispatch, isItemCore, components, serialVal]);

  useEffect(() => {
    if (bundle?.status) {
      setIsBundleUnpublished(bundle?.status === BundleStatusEnum.Unpublished);
    } else {
      setIsBundleUnpublished(true);
    }
  }, [bundle]);

  useEffect(() => {
    const products = bundle?.products;
    dispatch(pcBundleActions.setProducts(products || []));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bundle]);

  useEffect(() => {
    setComponents(bundleProducts || []);
  }, [bundleProducts]);

  return (
    <Card style={{ marginTop: '1em' }}>
      <CardContent>
        {!isBundleUnpublished && (
          <Alert severity="info">
            <FormHelperText>
              This build is either purchased or published. Change and save
              status to Unpublished to edit components
            </FormHelperText>
          </Alert>
        )}
        <Grid container>
          <Grid item xs={12}>
            <Box style={{ display: 'flex', alignItems: 'center' }}>
              <Typography>Scan Product Serial No.</Typography>
              <Checkbox
                disabled={!isBundleUnpublished}
                checked={isItemCore}
                onClick={() => setIsItemCore(!isItemCore)}
                color="primary"
              />
              <Typography>Core Component</Typography>
            </Box>
            <TextField
              disabled={!isBundleUnpublished}
              value={serialVal}
              error={!!errSerial}
              helperText={errSerial}
              fullWidth
              variant="outlined"
              placeholder="Scan serial no"
              onChange={(e) => setSerialVal(String(e.target.value))}
              onKeyDown={(e) => e.key === 'Enter' && addSerial()}
              InputProps={{
                endAdornment: <AddIconAdornment onClick={addSerial} />
              }}
            />
          </Grid>
        </Grid>
        {components[0] && (
          <Grid container style={{ marginTop: '1em', rowGap: '1em' }}>
            {components.map((i) => (
              <Grid item container key={i.serial_no} xs={12}>
                <Card key={i.serial_no} style={{ width: 'inherit' }}>
                  <CardContent style={{ padding: '1em' }}>
                    <Grid container style={{ columnGap: '2em' }}>
                      <Grid item>
                        <Typography style={{ fontWeight: 'bold' }}>
                          {i.product ?? i.product_name ?? ''}
                        </Typography>
                        <Typography color="textSecondary" variant="h6">
                          Category: {i?.category ?? i.category_name ?? ''}
                        </Typography>
                        <Typography color="textSecondary" variant="h6">
                          SN: {i?.serial_no ?? ''}
                        </Typography>
                        {/* ternary operator needed because we are treating is_core_component 
                        which is a number type as boolean type */}
                        {/* Using && operator will show a zero instead of hiding typography component */}
                        {i.is_core_component ? (
                          <Typography color="textSecondary" variant="h6">
                            Core Component
                          </Typography>
                        ) : null}
                      </Grid>
                      {/* Retain for easy revert */}
                      {/* <Grid
                            item
                            style={{
                              display: 'flex',
                              alignItems: 'center'
                            }}
                          >
                            <Checkbox
                              color="primary"
                              checked={i.is_core_component}
                            />
                            <Typography>Core Component</Typography>
                          </Grid> */}
                      <Grid
                        item
                        xs
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'end'
                        }}
                      >
                        <Button
                          disabled={!isBundleUnpublished}
                          onClick={() => removeSerial(i.serial_no ?? '')}
                        >
                          <ClearIcon
                            color="secondary"
                            style={{ fontSize: '2.5em' }}
                          />
                        </Button>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        )}
      </CardContent>
    </Card>
  );
};
